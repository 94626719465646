<template>
  <v-card>
    <v-card-title>
      User Information
      <v-spacer></v-spacer>
      <v-btn @click="$router.push({ name: 'MobileUserIndex'})" class="primary">Back</v-btn>
    </v-card-title>

    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>Profile</v-expansion-panel-header>
        <v-expansion-panel-content >
          <table class="table table-striped table-bordered" >
            <thead>
              <tr>
                <th style="width:30%" scope="col">Title</th>
                <th scope="col">Answer</th>
              </tr>
            </thead>
            <tbody v-for="(value, key, idx) in user.userProfile" :key="'p_'+key+idx">
              <tr v-if="!['__v', 'password'].includes(key)">
                <td class="bold">{{ key }}:</td>
                <td>{{ value }}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td class="bold">status</td>
                <td>{{userStatusMapper(user.status)}}</td>
              </tr>
            </tbody>
          </table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-for="(value, key, r_index) in user" :key="'r_id_'+r_index">
        <v-expansion-panel-header v-if="['permissions', 'bluetoothDevices', 'deviceInfo', 'demographics', 'appsInstalled', 'appsUsage'].includes(key)">{{ headerMapping[key] }}</v-expansion-panel-header>
        <v-expansion-panel-content v-if="['permissions', 'bluetoothDevices', 'deviceInfo', 'demographics', 'appsInstalled', 'appsUsage'].includes(key)">
          <table class="table table-striped table-bordered" v-if="['permissions', 'bluetoothDevices'].includes(key)">
            <thead>
              <tr>
                <th scope="col">List</th>
              </tr>
            </thead>
            <tbody >
              <tr v-for="(value, idx) in value" :key="'r_id_'+r_index+'_bp_'+idx">
                <td>{{value}}</td>
              </tr>
            </tbody>
          </table>
          <table class="table table-striped table-bordered" v-if="['deviceInfo', 'demographics', 'appsInstalled'].includes(key)">
            <thead>
              <tr>
                <th class="bold" style="width:30%" scope="col">Title</th>
                <th scope="col" style="width:50%">Answer</th>
                <th scope="col" style="width:20%">Shared</th>
              </tr>
            </thead>
            <tbody >
              <tr v-for="(value, idx) in value" :key="'r_id_'+r_index+'_dda_'+idx">
                <td class="bold">{{value.title}}</td>
                <td>{{value.answer}}</td>
                <td>{{value.shared}}</td>
              </tr>
            </tbody>
          </table>
          <table class="table table-striped table-bordered" v-if=" key =='appsUsage'">
            <thead>
              <tr>
                <th style="width:30%" scope="col">Title</th>
                <th scope="col">7 Days</th>
                <th scope="col">30 Days</th>
                <th scope="col">90 Days</th>
                <th scope="col">Shared</th>
              </tr>
            </thead>
            <tbody v-for="(value, a_idx) in value" :key="'r_id_'+r_index+'_a_'+a_idx">
              <tr>
                <td class="bold">{{value.title | capitalize}}</td>
                <td v-for="(a_value, key, au_idx) in value.answer" :key="'r_id_'+r_index+'_a_'+a_idx+'_au_'+au_idx">{{a_value}}</td>
                <td>{{value.shared}}</td>
              </tr>
            </tbody>
          </table>
        </v-expansion-panel-content >
      </v-expansion-panel>
      <!-- <v-expansion-panel>
        <v-expansion-panel-header>Locations</v-expansion-panel-header>
        <v-expansion-panel-content >
          <l-map ref="map" lazy
            style="height: 500px; z-index: 0"
          >
            <l-tile-layer :url="url"></l-tile-layer>
            <l-marker v-for="loc_value in locations" 
              :key="'loc_id_'+ loc_value._id"
              :lat-lng="loc_value.location.coordinates"
            >
              <l-popup :minWidth="100">{{ $moment(loc_value.created_at).format("DD/MM/YYYY, hh:mm A")}}<br>{{loc_value.location.coordinates}} </l-popup>
            </l-marker>
          </l-map>
        </v-expansion-panel-content>
      </v-expansion-panel> -->
    </v-expansion-panels>
  </v-card>
</template>


<script>
import RestResource from "./../../services/dataServiceMobile.js";
// import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
const service = new RestResource();

export default {
  components: {
    // LMap, LTileLayer, LMarker, LPopup
  },
  data() {
    return {
      user: {},
      header: null,
      headerMapping: {
        'permissions': "Permissions",
        'demographics': "Demographics",
        'deviceInfo': "Device Info",
        'appsInstalled': "Apps Installed",
        'bluetoothDevices': "Bluetooth Devices",
        'appsUsage': "Apps Usage",
      },
      url:'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      locations: [],
      center: [],
      statusMap: {
        A: "Active",
        D: "Deactivated",
        I: "Inactive",
        R: "Reset",
      }
    }
  },

  mounted() {
    this.loadUsers();
    setTimeout(function() { window.dispatchEvent(new Event('resize')) }, 2500);
  },

  methods: {
    userStatusMapper(status) {
      return this.statusMap[status] ? this.statusMap[status] : "NOT_AVAILABLE"
    },
    async loadUsers() {
      this.$setLoader();

      let mobileData = await service.findUser({ userId: this.$route.params.id })

      try {
        let userProfileData = await service.fetchUMUser({ userId: mobileData.data.userId })

        // let userLocations = await service.findUserLocationByUserId(this.$route.params.id)      
        // this.locations = userLocations.data
        // this.flipCoordinates(this.locations)
        
        this.user = mobileData.data
        this.user.userProfile = userProfileData.data
        this.user.userProfile.userId = mobileData.data.userId
        this.user.userProfile._id = mobileData.data._id
        
        this.$disableLoader();
      } catch (error) {
        this.$disableLoader();
      }
    },
    
    flipCoordinates(data){
      if(data !=null && data != undefined){
       let  child = []
        for(var i = 0; i < data.length; i++){
          child = data[i].location.coordinates
          child.reverse()
        }

        // this.center = [child[0],child[1]]
        // console.log(this.center)
        
      } else {
        return [ 0, 0 ]
      }
    },
  },

  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
};
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/bootstrap.scss";

.bold {
  font-weight: bold;
  max-width: 40%;
}
</style>